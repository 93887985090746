





















































































import { Component, Emit, Mixins, Prop, Watch } from "vue-property-decorator";
import { mapGetters } from "vuex";
import IAskuePointsByObject from "@/models/consumption-table/AskuePointsByObject.interface";
import IAskuePoint from "@/models/consumption-table/AskuePoint.interface";
import IChosenAskuePointProps from "@/models/consumption-table/ChosenAskuePointProps.interface";
import ITableHeader from "@/models/table/Header.interface";
import AppApiMixin from "../mixins/AppApi.vue";
import DocGetterMixins from "../mixins/DocGetter.vue";
import DownloadDocDropdown from "./DownloadDocDropdown.component.vue";
import DropdownWithDatePickerWithoutRange from "../hoc/DropdownWithDatePickerWithoutRange.vue";
import XButton from "../SimpleButton.vue";
import XDataTable from "../hoc/Table.vue";
import XDropdownWithRadioButtons from "../hoc/DropdownWithRadioButtons.vue";

@Component({
  components: {
    XDataTable,
    XDropdownWithRadioButtons,
    DropdownWithDatePickerWithoutRange,
    DownloadDocDropdown,
    XButton,
  },
  computed: { ...mapGetters("user", { openMonth: "month" }) },
})
class ConsumptionTable extends Mixins(AppApiMixin, DocGetterMixins) {
  @Prop({ required: true }) readonly pointsByObject!: IAskuePointsByObject;

  @Emit("update:point")
  public emitUpdatePoint(payload: IChosenAskuePointProps) {
    this.$emit("update:point", payload);
  }

  @Emit("update:wall")
  public emitUpdateWall(show: boolean): void {
    this.$emit("update:wall", show);
  }

  @Watch("wasChangeSyncValue")
  public syncValueChanged(): void {
    const { dateDropdown, modeDropdown, docTypeDropdown } = this;

    if (dateDropdown.show || modeDropdown.show || docTypeDropdown.show) {
      return this.emitUpdateWall(true);
    }

    this.emitUpdateWall(false);
  }

  data() {
    const headers: ITableHeader[] = [
      { text: "", value: "pointName" },
      { text: "", value: "action" },
    ];

    const modeDropdown = {
      items: ["План", "Факт"],
      selectedItemIndex: 0,
      show: false,
    };

    const dateDropdown = {
      date: "",
      show: false,
    };

    const docTypeDropdown = {
      selectedItemIndex: 0,
      show: false,
    };

    return {
      dateDropdown,
      docTypeDropdown,
      headers,
      modeDropdown,
    };
  }

  public get preparedItems(): Array<string[]> {
    const { pointsByObject } = this;

    return [
      [pointsByObject.objectName],
      ...pointsByObject.pointList.map((point) => [point["названиету"]]),
    ].map((arrayOfTd) => [...arrayOfTd, ""]);
  }

  public get minAndMaxDates(): string[] {
    const dateStringEndIndex = 7;
    const dateStrings = Array(2).fill(
      this.openMonth.slice(0, dateStringEndIndex)
    );

    if (!this.modeDropdown.selectedItemIndex) {
      const [currentMonth, nextMonth]: Date[] = dateStrings.map(
        (_) => new Date()
      );

      nextMonth.setMonth(nextMonth.getMonth() + 1);

      return [currentMonth, nextMonth].map((date) =>
        date.toISOString().slice(0, dateStringEndIndex)
      );
    }

    return dateStrings;
  }

  public get modeDropdownActivator(): string {
    const { items, selectedItemIndex } = this.modeDropdown;

    return items[selectedItemIndex];
  }

  /**
   * Определить произошел ли выбор нового значения в одном из дропдаунов,
   * а с помощью свойства-наблюдателя syncValueChanged обрабатывать данный кейс.
   */
  public get wasChangeSyncValue(): string {
    const { dateDropdown, docTypeDropdown, modeDropdown } = this;

    return [dateDropdown.show, modeDropdown.show, docTypeDropdown.show].join();
  }

  public downloadTemplate(): void {
    const {
      dateDropdown,
      docTypeDropdown,
      downloadConsumptionDocument,
      downloadDocument,
    } = this;

    downloadConsumptionDocument(
      docTypeDropdown.selectedItemIndex,
      dateDropdown.date
    )
      .then((response) => downloadDocument(response))
      .catch(console.error);
  }

  public getChosenPointProps(index: number): IChosenAskuePointProps {
    const { modeDropdown, pointsByObject } = this;

    // Установить значение по умолчанию, соответствующее объекту потребления,
    // а в случае выбора ТУ (т.е. index > 0) переопределить его.
    const pointProps: IChosenAskuePointProps = {
      id: pointsByObject.objectId,
      level: 1,
      mode: modeDropdown.selectedItemIndex,
      name: pointsByObject.objectName,
    };

    let chosenPoint: IAskuePoint | undefined;

    if (
      index &&
      (chosenPoint = pointsByObject.pointList.find((_, i) => i === index - 1))
    ) {
      pointProps.id = chosenPoint["лицевой"];
      pointProps.level = 0;
      pointProps.name = chosenPoint["названиету"];

      return pointProps;
    }

    return pointProps;
  }

  public onTableRow(index: number): void {
    const evtName = "update:dialogLoader";

    this.$emit(evtName, true);
    this.$emit("update:dialogTypeSlider", true);

    const {
      dateDropdown,
      chooseHourlyPoint,
      emitUpdatePoint,
      getChosenPointProps,
    } = this;

    const chosenPointProps = getChosenPointProps(index);

    const reqBody = {
      id: chosenPointProps.id,
      level: chosenPointProps.level,
      month: dateDropdown.date,
    };

    chooseHourlyPoint(reqBody).finally(() => {
      emitUpdatePoint(chosenPointProps);
      this.$emit(evtName, false);
    });
  }
}

export default ConsumptionTable;
